<template>
  <div>
    <v-card flat color="transparent" :loading="loadingCountryIntegrations">
        <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn icon color="primary" @click="addIntegration()"><v-icon>add_circle_outline</v-icon></v-btn>
        </v-toolbar>
      <v-card-text>
        <v-row justify="start" v-if="!loadingCountryIntegrations">
          <v-col
            cols="12"
            class="text-center"
            v-if="
              !loadingCountryIntegrations &&
              filterCountryIntegrations.length == 0
            "
          >
            <span style="color: grey"
              >No integrations available for your country</span
            >
          </v-col>
          <v-col
            cols="12"
            sm="3"
            v-for="integration in filterCountryIntegrations"
            :key="integration.id"
          >
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card
              @click="editIntegration(integration)"
                color="greyBase"
                style="border-radius: 20px"
                :elevation="hover ? 20 : 2"
              >
                <v-card-text>
                  <v-row justify="center" class="my-3">
                    <v-avatar size="128px" color="white">
                      <v-img contain :src="integration.icon"></v-img>
                    </v-avatar>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h4>
                        <v-icon color="grey" class="mr-2" small>code</v-icon>
                        {{ integration.name }}
                      </h4>
                      <h4 v-if="integration.governingBody">
                        <v-icon color="grey" class="mr-2" small
                          >business</v-icon
                        >
                        {{ integration.governingBody.name }}
                      </h4>
                      <h4 v-else>
                        <v-icon color="grey" class="mr-2" small
                          >business</v-icon
                        >
                        {{ integration.organisationName }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="modal" width="400px" persistent>
    <v-card>
        <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn text @click="saveItem" :loading="saving" color="secondary"> <v-icon>save</v-icon> </v-btn>
            <v-btn text v-if="item.id" @click="deleteItem" :loading="saving" color="red"> <v-icon>delete</v-icon> </v-btn>

            <v-btn text @click="modal=false">X</v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-select outlined dense label="Module" v-model="item.module" :items="['DOCUMENTATION', 'BOOKINGS']"></v-select>
                <v-select outlined dense label="Linked Form" v-model="item.formId" :items="forms" item-text="name" item-value="id"></v-select>

                <v-text-field outlined dense label="Name" v-model="item.name" clearable></v-text-field>
                <v-text-field outlined dense label="Organisation Name" v-model="item.organisationName" clearable></v-text-field>
                <v-textarea outlined dense label="Description" v-model="item.description" clearable></v-textarea>
                <v-text-field outlined dense label="Integration Code" v-model="item.code" clearable></v-text-field>
                <v-switch outlined dense label="Multiple Auth" v-model="item.multipleAuth" color="success"></v-switch>
                <v-switch outlined dense label="Share" v-model="item.share" color="success"></v-switch>
            </v-list>
        </v-card-text>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["country"],
  data: () => ({
    countryIntegrations: [],
    forms: [],
    item: {},
    loadingCountryIntegrations: false,
    modal: false,
    saving: false,
  }),
  watch: {
    "country.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getCountryIntegrations(val);
        }
      },
    },
  },
  computed: {
    filterCountryIntegrations() {
      let result = this.countryIntegrations;
      if (this.search) {
        result = result.filter(
          (item) =>
            item.name.toLowerCase().includes(this.search.toLowerCase()) ||
            (item.organisation &&
              item.organisation.name
                .toLowerCase()
                .includes(this.search.toLowerCase()))
        );
      }
      return result;
    },
  },
  created(){
    this.getForms()
  },
  methods: {
    addIntegration(){
        this.item = {
            module: 'DOCUMENTATION',
            countryRegionId: this.country.id
        }
        console.log(this.item)
        this.modal = true
    },
    async deleteItem(){
        this.saving = true
        await this.$API.updateAdminCountryIntegration({
            id: this.item.id,
            isActive: false,
            isDeleted: true
        })
        this.countryIntegrations = this.countryIntegrations.filter(x=>x.id !=this.item.id)
        this.modal= false
        this.item = {}
        this.saving = false
    },
    editIntegration(item){
        this.item = item
        this.modal = true
    },
    async getCountryIntegrations(id) {
      this.loadingCountryIntegrations = true;
      this.countryIntegrations = await this.$API.getAdminCountryIntegrations(
        id
      );
      this.loadingCountryIntegrations = false;
    },
    async getForms(){
        this.forms = await this.$API.getForms();
    },
    async saveItem(){
        this.saving = true
        if(this.item.id){
            await this.$API.updateAdminCountryIntegration(this.item)
        } else {
            let result = await this.$API.createAdminCountryIntegration(this.item)
            this.countryIntegrations.push(result)
        }
        this.modal= false
        this.item = {}
        this.saving = false
    }
  },
};
</script>